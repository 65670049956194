<template>
    <div>
        <TitleBar>轻页面管理中心</TitleBar>
        <div class="main-container">
            <div class="top">
                <h2>共{{ result.totalSize }}条轻页面素材</h2>
                <div class="query-form">
                    <Select
                        @on-change="reset"
                        v-model="query.status"
                        clearable
                        style="width: 210px; margin-right: 10px"
                        placeholder="请选择轻页面状态"
                    >
                        <Option value="1">草稿箱</Option>
                        <Option value="2">已发布</Option>
                        <Option value="3">暂停中</Option>
                    </Select>
                    <Input
                        @on-change="reset"
                        prefix="ios-search"
                        clearable
                        v-model="query.title"
                        placeholder="根据图文标题搜索"
                        style="width: 210px"
                    />
                </div>
            </div>
            <div class="lightpages">
                <div
                    class="lp-item create"
                    v-on:click="newLightPage"
                    v-bind:style="{ width: lpItemWidth + 'px' }"
                >
                    <div class="add">
                        <i
                            class="nazaio-iconfont nz-add"
                            style="font-size: 30px"
                        />
                    </div>
                    <h2>新增轻页面</h2>
                </div>
                <div
                    class="lp-item"
                    v-bind:style="{ width: lpItemWidth + 'px' }"
                    v-for="lightpage in result.data"
                    :key="lightpage.id"
                >
                    <LightPageItem
                        v-bind:id="lightpage.id"
                        v-bind:title="lightpage.title"
                        v-bind:description="lightpage.description"
                        v-bind:coverImg="lightpage.coverImg"
                        v-bind:status="lightpage.status"
                        v-bind:form="lightpage.form"
                        v-bind:updateTime="lightpage.updateTime"
                        v-on:publish="publishLightPage"
                        v-on:pause="pauseLightPage"
                        v-on:del="delLightPage"
                        v-on:detail="showDetail"
                        v-on:preview="showPreview"
                    >
                    </LightPageItem>
                </div>
            </div>
            <div
                class="pagination"
                v-if="result.totalSize / query.pageSize > 1"
            >
                <Page
                    @on-change="pageChange"
                    :total="result.totalSize"
                    :pageSize="query.pageSize"
                    show-elevator
                    show-total
                />
            </div>
        </div>
        <Modal v-model="modal" title="地址二维码" class="qrcode-detail">
            <h2>图文地址</h2>
            <div class="input"><Input long v-model="pageUrl" readonly /></div>
            <h2>二维码</h2>
            <div id="qrcode" class="qrcode-img" ref="qrcode"></div>
        </Modal>
        <Modal v-model="modal2" title="地址二维码" class="qrcode-detail">
            <h2>
                预览轻页面地址<span
                    style="font-size: 12px; color: #8494a6; margin-left: 10px"
                    >（预览连接不会收取任何数据）</span
                >
            </h2>
            <div class="input"><Input long v-model="pageUrl2" readonly /></div>
            <h2>二维码</h2>
            <div id="qrcode2" class="qrcode-img" ref="qrcode2"></div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import LightPageItem from "./components/LightPageItem";
import {
    getList,
    publish,
    pause,
    del,
    getPageUrl,
    getPreviewUrl,
} from "@/api/lightpage";
import QRCode from "qrcodejs2";

export default {
    name: "LightPage",
    components: {
        TitleBar,
        LightPageItem,
    },
    data() {
        return {
            query: {
                status: null,
                title: "",
                pageNum: 1,
                pageSize: 0,
            },
            result: {
                totalSize: 0,
                data: [],
            },
            modal: false,
            pageUrl: "",
            modal2: false,
            pageUrl2: "",
            screenWidth: window.innerWidth,
        };
    },
    created() {
        if (window.document.body.clientWidth < 1200) {
            this.query.pageSize = 11;
        } else {
            this.query.pageSize = 15;
        }
        this.getLightPageList();
    },
    mounted() {
        window.onresize = () => (this.screenWidth = window.innerWidth);
    },
    computed: {
        lpItemWidth() {
            if (window.innerWidth < 1024) {
                return Math.floor((1024 - 100 - 32) / 3);
            } else if (window.innerWidth < 1200) {
                return Math.floor((this.screenWidth - 100 - 32) / 3);
            } else {
                return Math.floor((this.screenWidth - 100 - 48) / 4);
            }
        },
    },
    methods: {
        newLightPage() {
            this.$router.push("/lightpage/add");
        },
        getLightPageList() {
            getList(this.query).then((response) => {
                this.result.totalSize = response.totalSize;
                this.result.data = response.data;
            });
        },
        publishLightPage(id) {
            this.$Modal.confirm({
                title: "确定发布该轻页面吗？",
                content:
                    "<p>发布后表单控件不可再次编辑，如需编辑其他信息，请暂停后进行编辑，是否确认发布？</p>",
                onOk: () => {
                    publish(id).then(() => {
                        this.$Message.success("轻页面发布成功！");
                        this.getLightPageList();
                    });
                },
            });
        },
        pauseLightPage(id) {
            this.$Modal.confirm({
                title: "确定对该轻页面进行暂停发布吗？",
                content:
                    "<p>暂停后轻页面将停止收集信息，确定对该轻页图文素材暂停发布吗？</p>",
                onOk: () => {
                    pause(id).then(() => {
                        this.$Message.success("轻页面已暂停成功！");
                        this.getLightPageList();
                    });
                },
            });
        },
        delLightPage(id) {
            this.$Modal.confirm({
                title: "确定删除该轻页面素材吗？",
                content: "<p>轻页面删除后将不可恢复，是否确认删除？</p>",
                onOk: () => {
                    del(id).then(() => {
                        this.$Message.success("轻页面已成功删除！");
                        this.getLightPageList();
                    });
                },
            });
        },
        showDetail(id) {
            getPageUrl(id).then((response) => {
                this.$refs.qrcode.innerHTML = "";
                this.modal = true;
                this.pageUrl = response;
                new QRCode("qrcode", {
                    width: 124,
                    height: 124,
                    text: response,
                });
            });
        },
        showPreview(id) {
            getPreviewUrl(id).then((response) => {
                this.$refs.qrcode2.innerHTML = "";
                this.modal2 = true;
                this.pageUrl2 = response;
                new QRCode("qrcode2", {
                    width: 124,
                    height: 124,
                    text: response,
                });
            });
        },
        reset() {
            this.query.pageNum = 1;
            this.getLightPageList();
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getLightPageList();
        }
    },
};
</script>
<style lang="less" scoped>
.main-container {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
    }
}

.top {
    display: flex;
    height: 48px;
    justify-content: space-between;

    h2 {
        font-size: 14px;
        color: #395069;
        font-weight: normal;
        margin: 0;
        line-height: 26px;
    }
}

.lightpages {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
    .lp-item {
        margin-left: 20px;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    .lp-item {
        margin-left: 20px;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }
}

.lp-item {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.1);
    height: 270px;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0px 4px 18px -8px rgba(0, 0, 0, 0.2);
    }
}

.create {
    cursor: pointer;

    .add {
        margin-top: 102px;
        text-align: center;
        color: #395069;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #395069;
        text-align: center;
        height: 20px;
        line-height: 20px;
        margin: 21px 0 0 0;
    }
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.qrcode-detail {
    h2 {
        font-size: 14px;
        font-weight: 500;
        color: #0a2a4c;
        margin: 0;
        height: 20px;
        line-height: 20px;
    }

    .input {
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .qrcode-img {
        text-align: center;
        margin: 16px auto 0 auto;
        width: 124px;
        height: 124px;
    }
}
</style>