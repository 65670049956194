<template>
    <div>
        <div
            class="info"
            @click="
                $router.push({
                    name: 'lightpage-report',
                    params: { id: id },
                })
            "
        >
            <div class="header">
                <div class="cover-img"><img v-bind:src="coverImg" /></div>
                <h2>{{ title }}</h2>
            </div>
            <p>{{ description }}</p>
            <div class="footer">
                <h3>更新于{{ updateTime }}</h3>
                <h4 v-if="form">表单</h4>
            </div>
        </div>
        <div class="action">
            <div class="status" v-bind:class="[statusClass]">
                {{ statusText }}
            </div>
            <div class="btns">
                <template v-if="status == 1"
                    ><!-- 草稿箱 -->
                    <div class="btn">
                        <Tooltip placement="top" content="编辑" :delay="1000">
                            <button @click="edit">
                                <i class="nazaio-iconfont nz-edit-square" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Tooltip placement="top" content="发布" :delay="1000">
                            <button @click="$emit('publish', id)">
                                <i class="nazaio-iconfont nz-send" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Tooltip placement="top" content="删除" :delay="1000">
                            <button @click="$emit('del', id)">
                                <i class="nazaio-iconfont nz-delete" />
                            </button>
                        </Tooltip>
                    </div>
                </template>
                <template v-else-if="status == 2"
                    ><!-- 发布 -->
                    <div class="btn">
                        <Tooltip placement="top" content="编辑" :delay="1000">
                            <button @click="edit">
                                <i class="nazaio-iconfont nz-edit-square" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Tooltip placement="top" content="暂停发布" :delay="1000">
                            <button @click="$emit('pause', id)">
                                <i class="nazaio-iconfont nz-stop-circle" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Poptip placement="bottom-end" width="100" offset="12" transfer-class-name="more-action">
                            <button>
                                <i class="nazaio-iconfont nz-more-horiz" />
                            </button>
                            <div slot="content" class="sub-btns">
                                <div class="sub-btn">
                                    <Tooltip placement="top" content="地址二维码" :delay="1000">
                                        <button @click="$emit('detail', id)">
                                            <i class="nazaio-iconfont nz-qrcode-ordinary" />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div class="sub-btn">
                                    <Tooltip placement="top" content="导出" :delay="1000">
                                        <button @click="exportData">
                                            <i class="nazaio-iconfont nz-export" />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Poptip>
                    </div>
                </template>
                <template v-else-if="status == 3"
                    ><!-- 暂停 -->
                    <div class="btn">
                        <Tooltip placement="top" content="编辑" :delay="1000">
                            <button @click="edit">
                                <i class="nazaio-iconfont nz-edit-square" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Tooltip placement="top" content="发布" :delay="1000">
                            <button @click="$emit('publish', id)">
                                <i class="nazaio-iconfont nz-send" />
                            </button>
                        </Tooltip>
                    </div>
                    <div class="btn">
                        <Poptip placement="bottom-end" width="140" offset="12" transfer-class-name="more-action">
                            <button>
                                <i class="nazaio-iconfont nz-more-horiz" />
                            </button>
                            <div slot="content" class="sub-btns">
                                <div class="sub-btn">
                                    <Tooltip placement="top" content="地址二维码" :delay="1000">
                                        <button @click="$emit('preview', id)">
                                            <i class="nazaio-iconfont nz-qrcode-ordinary" />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div class="sub-btn">
                                    <Tooltip placement="top" content="导出" :delay="1000">
                                        <button @click="exportData">
                                            <i class="nazaio-iconfont nz-export" />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div class="sub-btn">
                                    <Tooltip placement="top" content="删除" :delay="1000">
                                        <button @click="$emit('del', id)">
                                            <i class="nazaio-iconfont nz-delete" />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Poptip>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { exportExcel } from "@/api/lightpage";

export default {
    name: "LightPageItem",
    props: ["id", "title", "description", "updateTime", "coverImg", "status", "form"],
    computed: {
        statusText() {
            if (this.status == 1) {
                return "草稿箱";
            } else if (this.status == 2) {
                return "已发布";
            } else if (this.status == 3) {
                return "暂停中";
            } else {
                return "未知";
            }
        },
        statusClass() {
            if (this.status == 1) {
                return "status-save";
            } else if (this.status == 2) {
                return "status-publish";
            } else if (this.status == 3) {
                return "status-stop";
            }
            return "";
        },
    },
    methods: {
        edit() {
            this.$router.push({
                name: "lightpage-edit",
                params: { id: this.id },
            });
        },
        exportData() {
            exportExcel(this.id).then((response) => {
                let blob = new Blob([response.data], {
                    type: "application/vnd.ms-excel",
                });
                let fileName = "表单数据" + new Date().getTime() + ".xlsx";
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.info {
    height: 214px;
    border-bottom: 1px solid #e8eaec;

    p {
        margin: 0 16px 16px 16px;
        font-size: 14px;
        color: #8494a6;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
        display: -webkit-box;
        height: 60px;
    }
}

.header {
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    display: flex;

    .cover-img {
        float: left;
        height: 72px;
        flex: 0 0 72px;
        margin-right: 12px;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    h2 {
        flex: 1 1 auto;
        float: right;
        width: 154px;
        margin: 0;
        padding: 0;
        font-size: 16px;
        color: #0a2a4c;
        font-weight: 400;
        line-height: 24px;
        max-height: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.footer {
    overflow: hidden;
    h3 {
        float: left;
        margin: 0 0 16px 16px;
        font-weight: normal;
        font-size: 13px;
        color: #8494a6;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        height: 18px;
    }

    h4 {
        float: right;
        margin: 0 16px 0 0;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        width: 38px;
        height: 18px;
        line-height: 18px;
        border-radius: 9px;
        border: 1px solid #ff9900;
        color: #ff9900;
        box-sizing: border-box;
    }
}

.action {
    position: relative;
}

.status {
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    height: 24px;
    width: 55px;
    border-radius: 4px;
    margin-left: 16px;
    text-align: center;
    margin-top: 15px;
    float: left;
}

.status-save {
    background-color: #c6c9cf;
}

.status-publish {
    background-color: @fc-main-color;
}

.status-stop {
    background-color: #ed4014;
}

.btns {
    float: right;
    margin-top: 18px;

    .btn {
        margin-right: 24px;
        float: left;

        /deep/ .more-action {
            min-width: 80px;
        }
    }

    button {
        color: #8494a6;
        width: 18px;
        height: 18px;
        border: none;
        outline: none;
        font-size: 14px;
        background-color: transparent;
        display: block;
        position: relative;
        cursor: pointer;

        &:hover {
            color: @fc-main-color;
        }
    }
}

.sub-btns {
    overflow: hidden;
}

.sub-btn {
    margin-right: 24px;
    float: left;

    &:last-child {
        margin-right: 0;
    }
}
</style>
